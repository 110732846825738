import React from "react";
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'


function Footer() {
  return (
    <div>
        <Card.Footer className="text-muted"><h3 style={{textAlign: "center", fontSize: "10px", }} > Copyright IndySolo Productions. 2018</h3></Card.Footer>
        
    </div>
  );
}

export default Footer;
