import React from "react";
import Button from "react-bootstrap/Button";

function About() {
  return (
    <div>
        <h1 style={{textAlign: "center"}}>The Hosts</h1>
        <h3 style={{textAlign: "center"}}>We are a rag tagged group of brothers that love to watch movies and talk about them. </h3>
        <Button style={{marginLeft: "45%"}} > Learn More</Button>
        
        <hr/>
    </div>
  );
}

export default About;