import React from "react";

function Nav() {
  return (
    <div>
      <nav> 
          <h1 style={{textAlign: "center", backgroundColor: "#000", color: "white",  marginBottom: "0"}}>Welcome to The ScreenSquad Podcast page!</h1>
      </nav>
    </div>
  );
}

export default Nav;